import React, { Component } from 'react';
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'

import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import withStyles from '@material-ui/core/styles/withStyles'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core';
import {Link , Redirect} from 'react-router-dom';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress'


const styles={
    loginBox :{
        maxWidth:500,
        textAlign:'center',
        margin: 'auto'
    }

}


class login extends Component {
    constructor() {
        super();


        this.state={
            email : '',
            password : '',
            loading : false,
            errors : {} 
        }
    }

    handleSubmit = (event)=>{
        event.preventDefault();
        this.setState({
            loading:true
        })

        const userData ={
            email : this.state.email,
            password : this.state.password
        }

        axios.post('https://asia-southeast2-spacebrain-775b9.cloudfunctions.net/api/login', userData)
        .then((res)=>{
            console.log(res.data)
            localStorage.setItem('FBIdToken', `Bearer ${res.data.token}`)
            this.setState({
                loading : false
            })

            window.location.pathname == '/login' ? window.location.replace(`http://localhost:3000/`) : window.location.replace(`http://localhost:3000${window.location.pathname}`);
            
        }).catch(err=>
            {
            try{
                this.setState(
                    {
                        errors : err.response.data,
                        loading : false
                    }
                    
                )

                console.log(err.response.data)
            }catch{
                console.log(err);
            }
            
            
        });
    }

    handleChange =(event) =>{
        this.setState({
            [event.target.name]: event.target.value
        })

        console.log({
            [event.target.name]: event.target.value
        });
    }
    render() {
        const {classes} = this.props;
        const { errors, loading } = this.state;
        return (
            <Grid container>
                {/* <Grid item sm={4}></Grid> */}

                <Grid item sm={12} xs={12} align='center' className={classes.loginBox} >
                    <Box p={3}>
                    <Typography variant='h5'>
                        Login
                    </Typography>

                    {loading && (
                        <CircularProgress></CircularProgress>
                    )}
                    </Box>

                    <form 
                    onSubmit={this.handleSubmit}>
                    <Box p={1}>
                    <TextField
                        label = "Email"
                        helperText = {errors.email}
                        error = {errors.email ? true : false}
                        // required = {true}
                        id="email"
                        size = 'small'
                        name='email'
                        type ='email'
                        value = {this.state.email}
                        variant='outlined'
                        fullWidth = {true}
                        onChange={this.handleChange}
                        margin='normal'
                    />

                    <TextField
                        label = "Password"
                        helperText = {errors.password}
                        error = {errors.password ? true : false}
                        // required = {true}
                        id="password"
                        size = 'small'
                        name='password'
                        type ='password'
                        value = {this.state.password}
                        variant='outlined'
                        fullWidth = {true}
                        onChange={this.handleChange}
                        margin='normal'
                    />
                    <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                    fullWidth>Login</Button>
                    {errors.general &&(
                        <Box p={1}>
                        <Typography variant='body2' color='error'>
                            {errors.general}
                        </Typography>
                        </Box>
                    )}
                    </Box>
                    </form>
                    <Box>
                    {window.location.pathname == '/login' && (
                            <Typography>
                            Already have an account? <Button component={Link} to='/signup'> create a new ccount </Button>
                        </Typography>
                        )}
                    </Box>
                </Grid>

                {/* <Grid item sm={4}></Grid> */}
            </Grid>
            
        );
    }
}

export default withStyles(styles)(login);