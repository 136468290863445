import React, {
    Component
} from "react";
import axios from "axios";
import $ from "jquery";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import GoogleAuth from './GoogleAuth'
import Login from '../components/login'
import Signup from '../components/signup'
import jwtDecode from 'jwt-decode';
import CircularProgress from '@material-ui/core/CircularProgress'



import * as Survey from "survey-react";


import 'bootstrap/dist/css/bootstrap.css';
// import "survey-react/modern.css";
import "survey-react/survey.css";
import { Typography } from "@material-ui/core";

// Survey.StylesManager.applyTheme("bootstrap");

let currenUserId = 'Anonymous'
class GetSurvey extends Component {

    constructor() {
        super();


        


        this.state = {
            googleToken: '',
            user_id : 'Anonymous',
            invitation_params: undefined,
            loading: true,
            Survey_completed : false,
            survey_completed_message : '',
            survey_failed: false,
            survey_error : '',
            invited_by : 'none'
        }

    }


    componentDidMount() {


        var defaultThemeColors = Survey
            .StylesManager
            .ThemeColors["bootstrap"];

        defaultThemeColors["$main-color"] = "red";
        defaultThemeColors["$main-hover-color"] = "green";
        defaultThemeColors["$text-color"] = "blue";
        defaultThemeColors["$header-color"] = "#ff0000";

        defaultThemeColors["$header-background-color"] = "#ff0000";
        defaultThemeColors["$body-container-background-color"] = "red";


        Survey
            .StylesManager
            .applyTheme();


        const invited_by = this.props.match.params.invitedBy ;
        this.setState({invited_by: invited_by ? invited_by : "none"})
        console.log(this.props.match.params);

        const inv_id_req = this.props.match.params.id;
        axios.post(`https://asia-southeast2-spacebrain-775b9.cloudfunctions.net/invite/getSurvey`, { survey_id: inv_id_req })
            .then(res => {
                this.setState({
                    invitation_params: res.data.res_survey.survey_body,
                    loading: false,
                })

                console.log(res.data.res_survey);
            })



    }
    handleGoogleAuth = (data) => {
        currenUserId = jwtDecode(data.token).user_id
        this.setState({ googleToken: `Bearer ${data.token}`,
                        user_id: currenUserId})
                        
        console.log(`HELOOO ${this.state.user_id}`);
    }
    render() {
        var json;


        // var surveyValueChanged = function (sender, options) {
        //     var el = document.getElementById(options.name);
        //     if (el) {
        //         el.value = options.value;
        //         console.log(el.value)
        //     }
        // };
        if (this.state.invitation_params) {

            json = this.state.invitation_params;


        } else {
            json = { pages: [{ name: "page1" }] }
        }

        const survey = new Survey.Model(json);

        survey.onComplete
            .add((result)=>{
                axios.post('https://asia-southeast2-spacebrain-775b9.cloudfunctions.net/invite/addSurveyResponse',
                {userId : currenUserId ? currenUserId : "Anonymous",
                data : result.data, 
            invitedBy : this.state.invited_by})
                .then((res)=>{
                    this.setState({Survey_completed : true,
                    survey_completed_message : res.data.message})
                }).catch((error)=>{
                    this.setState(
                        {survey_failed : true,
                            survey_error : error
                        })
                })
            });








        return (
            <Grid container justify='space-around' >
                <Grid
                    item
                    sm={1}
                ><Box>&nbsp;</Box></Grid>
                <Grid item sm={8} spacing={1}>
                    {this.state.loading && (
                        <Box sm={8} p={2} align='center'>
                            <CircularProgress />
                            <Typography>Loading survey ...</Typography>
                        </Box>
                    )
                    }
                    {this.state.invitation_params && (
                        <Box sm={8} p={2}>
                            < Survey.Survey model={survey} />
                        </Box>
                    )}


                </Grid>
                <Grid item sm={3}>

                    <Box sm={12}><GoogleAuth
                        onGoogleAuth={this.handleGoogleAuth} /></Box>
                </Grid>

            </Grid>
        );
    }
}




export default GetSurvey;