import React from 'react';
import '../App.css';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import {Link} from 'react-router-dom';
import TextField from '@material-ui/core/TextField'



import { signInWithGoogle, auth } from '../firebase/firebase.util';
import { Typography } from '@material-ui/core';
import jwtDecode from 'jwt-decode';



const styles={
  SMlogo:{
    width: '100px',
    height: '100px'
  },
  subTitleSM_text:{
    marginTop:10,
    fontWeight: 'bold'
  },
  maxWidthBox:{
    maxWidth:200,
    overFlow: 'hidden'
  },
  ads_text:{
    marginTop:10,
  },
  hoverOverthetextField:{
    cursor: 'pointer',
  }

}


class GooglAuth extends React.Component {

  constructor() {
    super();

    this.state = {
      currentUser: null,
      textFieldCopied : false,
      authenticated : false,
      token : undefined
    };
  }

  unsubscribeFromAuth = null;

  componentDidMount() {
    this.unsubscribeFromAuth = auth.onAuthStateChanged(user => {
      this.setState({ currentUser: user,
      authenticated: true,
      token : `Bearer ${user.za}`});
      localStorage.setItem('userIdToken', `Bearer ${user.za}`);
      // console.log(jwtDecode(localStorage.userIdToken));
      this.props.onGoogleAuth({token : `Bearer ${user.za}`})
    });
  }

  componentWillUnmount() {
    this.unsubscribeFromAuth();
  }

   handleCopyText = (event)=>{
    event.target.select();
    navigator.clipboard.writeText(event.target.value);
    this.setState({textFieldCopied: true})
    
  }

  render() {
    const {classes} = this.props;
    return (
        <Grid container >
        <Box m={1} fullWidth='true' >
                <Box p={1} fullWidth={true}>

                  <Box m={2}>
                  <img 
              src='https://images.squarespace-cdn.com/content/5cb5682ec2ff617789977d59/1555481526718-QN1KVJ025J25EDIUXN2L/TRIAL%2B4%2BFinal%2BLogo%2BCOLOR-01.jpg?format=1500w&content-type=image%2Fjpeg'
              className={classes.SMlogo}>
              </img>
              {/* <Typography align='left' className={classes.subTitleSM_text}>
                Spacematch is the Uber® of flexible workspaces
              </Typography> */}
                  </Box>
        {

          this.state.currentUser ?
            (
                 <Box sm={12} m={2}>
                   <Typography  className={classes.ads_text}>
                     Thank you {this.state.currentUser.displayName.split(" ")[0]} for subscribing to the waiting list.
                   </Typography>

                   <Typography className={classes.ads_text}>
                     
                     Please, share the following link with your network.
                    </Typography>
                    <Box >
                  
                    <TextField
                    fullWidth={true}
                    helperText={this.state.textFieldCopied? 'Copied to clipboard' : ''}
                    variant='outlined'
                    label='click to copy the link'
                    margin='normal'
                    value={`https://spacebrain-775b9.web.app/getSurvey/betaVersionSurveyPublic/${this.state.currentUser.uid}`}
                    onClick={this.handleCopyText}
                    className={classes.hoverOverthetextField}
                    >
                    
                      </TextField>
                    {/* </a> */}
                    </Box>
                   {
                    //  console.log(this.state.currentUser)
                     
                   }
                {/* <img 
                src={this.state.currentUser.photoURL} 
                width={40} 
                height={40}
                />
                <Typography variant="p" color="primary"> {this.state.currentUser.displayName.split(" ")[0]}</Typography>
              
              {/* <div>Name: {this.state.currentUser.displayName}</div>
              <div>Email: {this.state.currentUser.email}</div> */}
                <Box>
              <Button 
              color='primary' 
              variant='outlined'
              onClick={() => auth.signOut()} fullWidth={true}>LOG OUT</Button>
               </Box>
              </Box>
            
            ) :
            <Box justifyContent="center" m={1} p={1} bgcolor="background.paper" fullWidth={true}>
              <Typography >
                     Subscribe to the waiting list. 
              </Typography>
            <Button  
              color='textPrimary' 
              variant='outlined'
              fullWidth={true}
              onClick={signInWithGoogle}>SIGN IN WITH GOOGLE</Button>
            </Box>

        }
        </Box>
        </Box>
      </Grid >
    );
  }
}


export default withStyles(styles)(GooglAuth);