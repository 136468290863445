import React, { Component } from 'react';
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'

import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import withStyles from '@material-ui/core/styles/withStyles'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core';
import {Link} from 'react-router-dom';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress'


const styles={
    signUPtitle:{
        marginBottom: 0,
        paddintBottom: 0
    },
    formBox : {
        paddingTop: 0,
        margin: 0
    }

}


class signup extends Component {
    constructor() {
        super();


        this.state={
            userName : '',
            email : '',
            password : '',
            confirmPassword : '',
            loading : false,
            errors : {} 
        }
    }

    handleSubmit = (event)=>{
        event.preventDefault();
        this.setState({
            loading:true
        })

        const NewuserData ={
            userName : this.state.userName,
            email : this.state.email,
            password : this.state.password,
            confirmPassword : this.state.confirmPassword
        }

        axios.post('https://asia-southeast2-spacebrain-775b9.cloudfunctions.net/api/signup', NewuserData)
        .then((res)=>{
            console.log(res.data)
            localStorage.setItem('FBIdToken', `Bearer ${res.data.newToken}`)
            this.setState({
                loading : false
            })

            this.props.history.push(window.location.pathname == '/signup' ? '/' : window.location.pathname);
        }).catch(err=>
            {

            this.setState(
                {
                    errors : err.response.data,
                    loading : false
                }
            )
            console.log(err.response.data)
        });
    }

    handleChange =(event) =>{
        this.setState({
            [event.target.name]: event.target.value
        })

        console.log({
            [event.target.name]: event.target.value
        });
    }
    render() {
        const {classes} = this.props;
        const { errors, loading } = this.state;
        return (
            <Grid container>
                {/* <Grid item sm={4}></Grid> */}

                <Grid item sm={12} xs={12} align='center' >
                    <hr></hr>
                    <Box p={1}>
                    <Typography variant='h5' className={classes.signUPtitle}>
                        Create account
                    </Typography>
                    
                    {loading && (
                        <CircularProgress></CircularProgress>
                    )}
                    </Box>

                    <form 
                    onSubmit={this.handleSubmit}>
                    <Box p={1} className={classes.formBox}>

                    <TextField
                        label = "Your Name"
                        helperText = {errors.userName}
                        error = {errors.userName ? true : false}
                        // required = {true}
                        id="userName"
                        size = 'small'
                        name='userName'
                        value = {this.state.userName}
                        variant='outlined'
                        fullWidth = {true}
                        onChange={this.handleChange}
                        margin='normal'
                    />
                    <TextField
                        label = "Email"
                        helperText = {errors.email}
                        error = {errors.email ? true : false}
                        // required = {true}
                        id="email"
                        size = 'small'
                        name='email'
                        type ='email'
                        value = {this.state.email}
                        variant='outlined'
                        fullWidth = {true}
                        onChange={this.handleChange}
                        margin='normal'
                    />

                    <TextField
                        label = "Password"
                        helperText = {errors.password}
                        error = {errors.password ? true : false}
                        // required = {true}
                        id="password"
                        size = 'small'
                        name='password'
                        type ='password'
                        value = {this.state.password}
                        variant='outlined'
                        fullWidth = {true}
                        onChange={this.handleChange}
                        margin='normal'
                    />

                    <TextField
                        label = "Password"
                        helperText = {errors.confirmPassword}
                        error = {errors.confirmPassword ? true : false}
                        // required = {true}
                        id="confirmPassword"
                        size = 'small'
                        name='confirmPassword'
                        type ='password'
                        value = {this.state.confirmPassword}
                        variant='outlined'
                        fullWidth = {true}
                        onChange={this.handleChange}
                        margin='normal'
                    />

                    <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                    fullWidth>Login</Button>
                    {errors.general &&(
                        <Box p={1}>
                        <Typography variant='body2' color='error'>
                            {errors.general}
                        </Typography>
                        </Box>
                    )}
                    </Box>
                    </form>
                    <Box>
                        {window.location.pathname == '/signup' && (
                            <Typography>
                            Already have an account? <Button component={Link} to='/login'> Login </Button>
                        </Typography>
                        )}
                        
                    </Box>
                </Grid>

                {/* <Grid item sm={4}></Grid> */}
            </Grid>
            
        );
    }
}

export default withStyles(styles)(signup);