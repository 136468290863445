import ReactMapboxGl, { Layer, Feature, Source, GeoJSONLayer, Popup } from 'react-mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import jwtDecode from 'jwt-decode'
import firebase from '../firebase/firebase.util';
import $ from 'jquery';
import Skeleton from '@material-ui/lab/Skeleton';
import TextField from '@material-ui/core/TextField'
import { Pannellum } from "pannellum-react";
import myImage from "../images/milan.JPG";
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import Save from '@material-ui/icons/Save';
import Publish from '@material-ui/icons/Publish'
import Add from '@material-ui/icons/Add'
import Star from '@material-ui/icons/Star'
import { Link, useParams } from "react-router-dom";

import {InfluxDB} from 'influx';

import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';

// turf
import area from '@turf/area';


import { v4 as uuidv4 } from 'uuid';

// Step 2 - Include the react-fusioncharts component
import ReactFC from "react-fusioncharts";

// Step 3 - Include the fusioncharts library
import FusionCharts from "fusioncharts";

// Step 4 - Include the chart type
import Column2D from "fusioncharts/fusioncharts.charts";
import Hbullet from "fusioncharts/fusioncharts.widgets"
import Line from "fusioncharts/fusioncharts.charts"

// Step 5 - Include the theme as fusion
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";




import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Divider, Paper } from '@material-ui/core';
import { ContactlessOutlined } from '@material-ui/icons';
import axios from 'axios';


// enums
const NONE = -1;
const BUILDING = 0;
const SPACE = 1;
const WORKSPACE = 2;


ReactFC.fcRoot(FusionCharts, Column2D, FusionTheme);
ReactFC.fcRoot(FusionCharts, Hbullet, FusionTheme);
ReactFC.fcRoot(FusionCharts, Line, FusionTheme);

const Map = ReactMapboxGl({
    accessToken:
        "pk.eyJ1IjoibWFobW91ZG91ZjExMSIsImEiOiJjazcyb2xydWowNGo4M2t1dXhmMGN0M3dpIn0.sW9s_aQkI5eIRcN50sXk3w",

});

const db = firebase.firestore();

let workspace_walls;
let workspace_spaces;
let workspace_workspaces;
let workspace_stairlines;
let workspace_hiddenlines;
const workspace_hiddenlines2 = 'https://gist.githubusercontent.com/MahmoudAbdelRahman/25717d640fe075b512e34df5f7a9a950/raw/1812216a7e7a2bd2cea1330eee7b324404471c12/sde4_level3_hidden2.json'
const token = localStorage.FBIdToken;



let desk_elements;
// let current_space_id;
let current_user_id;
let decodedToken;
// let all_data = null;
let ws_data = false;
let all_workspaces_updated = false;

let combined_space_data = []
let buildings;
let floors;
let spaces;
let workspaces;
let done = false;


let building_data = {};
let floor_data = {};
let space_data = {};
let workspace_data = {};

//handle selsections 
let previous_selectedSapce = "00";
// let current_selection = NONE;

const available_sensors=["ASB005221418",
"ASB005151418",
"ASB102871830",
"ASB004651418",
"ASB006031418",
"ASB007881418",
"ASB104571831",
"ASB105281831",
"ASB102181830",
"ASB004101418",
"ASB116981902",
"ASB004611418",
"ASB105891831",
"ASB102461830",
"ASB005431418",
"ASB104791831"]

const styles = {
    plotbox: {
        maxWidth: 500,
        width: 300,
        height: 300,
        position: "relative"
    },
    buttonUPloadImages: {
        fontSize: 'x-small',
        marginTop: 5
    },
    customClass: {
        bgcolor: "white"
    },
    addListingButton: {
        color: "#fsfs0a",
        fontSize: "x-small"
    },
    tinyInfo: {
        fontSize: "x-small"
    }
}
class space_dashboard extends Component {
    constructor() {
        super();

        this.state = {
            popup_lngLat: [0.0, 0.0],
            center_loc: [103.827611, 1.3550019],
            zoom: 10.,
            desk_stauts: false,
            desk_visible: false,
            desk_data: undefined,
            desk_data_loaded: false,
            desk_data_loading: "Loading map",
            desks: [],
            space_loaded: false,
            n_floors: 0,
            space_desks: null,
            desk_selected: true,
            selected_desk_data: null,
            workspaceName: '',
            workspaceType: '',
            workspace2dImage: '',
            innerGridWidth: '200px',
            dashboardLeftPanelWidth: 200,
            buildings_loaded: false,
            floors_loaded: false,
            spaces_loaded: false,
            workspaces_loaded: false,
            all_loaded: false,
            selectedListing: null,
            floor_is_selected: false,
            info_1: "",
            info_1_loaded: false,
            info_2: "",
            info_2_loaded: false,
            show_info: false,
            current_selection: NONE,
            topLeft_space_occupancy: 121,
            topLeft_space_vacancy: 40,
            topLeft_title: "Listings occupancy",
            sensor_data_loading: false,
            sensor_data_values:[]

        }

    }

    componentDidMount() {

        if (token) {
            decodedToken = jwtDecode(token);
            current_user_id = decodedToken.user_id;
            this.getListingData();
            // this.updatetheDatabaseforOnce();
        }

    }


    // should be moved to another backend place. 
    updatetheDatabaseforOnce = () => {
        if (!done) {
            buildings = [];
            floors = {}

            db.collection("spaces").where("space_owner", "==", current_user_id)
                .get()
                .then((snapshot) => {
                    snapshot.forEach(doc => {
                        const combdata = doc.data();
                        const parsed_space_data = JSON.parse(combdata.space_data);
                        combined_space_data.push(parsed_space_data)
                        const b_uid = `building_${uuidv4()}`

                        floors[b_uid] = parsed_space_data.geoJson.map(x => (
                            {
                                id: `floor_${uuidv4()}`,
                                floor_number: x.level,
                                building_id: b_uid,
                                floor_owner: combdata.space_owner,
                                floor_walls_geoJson: JSON.stringify(x.walls),
                                floor_hiddenlines_geoJson: JSON.stringify(x.hiddenlies),
                                floor_stairlines_geoJson: JSON.stringify(x.stair_lines),
                                floor_spaces_geoJson: x.spaces.features.map(y => (
                                    {
                                        space_id: `space_${uuidv4()}`,
                                        space_owner: combdata.space_owner,
                                        space_name: `unnamed space`,
                                        space_geoJson: JSON.stringify(y)
                                    }
                                )),
                                floor_workSpaces_geoJson: x.desks.features.map(y => (
                                    {
                                        workspace_id: `workspace_${uuidv4()}`,
                                        workspace_owner: combdata.space_owner,
                                        workspace_name: `unnamed workspace`,
                                        workspace_geoJson: JSON.stringify(y)
                                    }
                                )),

                            }
                        )
                        )
                        const thisBuilding = {
                            id: b_uid,
                            building_name: combdata.space_name,
                            building_location: parsed_space_data.location,
                            building_owner: combdata.space_owner,
                            building_listing_id: doc.id,
                            building_n_floors: parsed_space_data.n_floors,
                            building_floors: floors[b_uid].map(x => x.id)
                        }

                        buildings.push(thisBuilding)


                    });
                }).then(() => {
                    // this.updateBuidingDatabase();
                    // this.updateFloorDatabase();
                    // this.updateSpaceDatabase();
                    // this.updateWorkspaceDatabase();
                    // console.log(floors)
                }).then(() => {
                    done = false;
                })

        }

    }

    updateBuidingDatabase = () => {
        let writeBatch = db.batch();
        console.log(buildings)
        buildings.forEach(building => {
            let documentRef = db.collection('Building').doc(building.id);
            writeBatch.set(documentRef, {
                ...building
            });
        });


        writeBatch.commit().then(() => {
            console.log("Building added")
        });
    }

    updateFloorDatabase = () => {
        let writeBatch = db.batch();
        // console.log(floors)

        for (const key in floors) {
            if (Object.hasOwnProperty.call(floors, key)) {
                const floor = floors[key];
                floor.forEach(element => {
                    let documentRef = db.collection('Floor').doc(element.id);
                    writeBatch.set(documentRef, {
                        ...element
                    });
                });
            }
        }


        writeBatch.commit().then(() => {
            console.log("Floor added")
        });

    }

    updateSpaceDatabase = () => {
        let writeBatch = db.batch();

        for (const key in floors) {
            if (Object.hasOwnProperty.call(floors, key)) {
                const floor = floors[key];
                floor.forEach(element => {
                    console.log(element.floor_spaces_geoJson);
                    element.floor_spaces_geoJson.forEach(thisspace => {
                        let documentRef = db.collection('Space').doc(thisspace.space_id);
                        writeBatch.set(documentRef, {
                            building_id: key,
                            floor_id: element.id,
                            sensors_apis: [{ location: [], url: "", token: "" }],
                            ...thisspace
                        });
                    })

                });
            }
        }


        writeBatch.commit().then(() => {
            console.log("Space added")
        });

    }

    updateWorkspaceDatabase = () => {
        let writeBatch = db.batch();
        // console.log(floors)

        for (const key in floors) {
            if (Object.hasOwnProperty.call(floors, key)) {
                const floor = floors[key];
                floor.forEach(element => {
                    console.log(element.floor_workSpaces_geoJson);
                    element.floor_workSpaces_geoJson.forEach(thisworkspace => {
                        let documentRef = db.collection('WorkSpace').doc(thisworkspace.workspace_id);
                        writeBatch.set(documentRef, {
                            building_id: key,
                            floor_id: element.id,
                            panorama: `https://firebasestorage.googleapis.com/v0/b/spacebrain-775b9.appspot.com/o/milan.JPG?alt=media&token=d4feae5f-23bf-4d5f-9eae-6db98241d5d5`,
                            images: [],
                            ...thisworkspace
                        });
                    })

                });
            }
        }


        writeBatch.commit().then(() => {
            console.log("workspace added")
        });

    }

    getListingData = () => {
        db.collection("Building").where("building_owner", "==", current_user_id).get()
            .then((snapshot) => {
                snapshot.forEach(building_ => {
                    building_data[building_.id] = building_.data()
                });
                console.log("Buildings loaded")
            }).then(() => {
                this.setState({ buildings_loaded: true });
                db.collection("Floor").where("floor_owner", "==", current_user_id)
                    .get().then((snapshot) => {
                        snapshot.forEach(floordoc => {
                            floor_data[floordoc.data().id] = floordoc.data();
                        });
                    }).then(() => {
                        this.setState({ floors_loaded: true });
                        db.collection("Space").where("space_owner", "==", current_user_id)
                            .get().then((snapshot) => {
                                snapshot.forEach(spacedoc => {
                                    space_data[spacedoc.data().floor_id] = spacedoc.data();
                                });
                            }).then(() => {
                                this.setState({ spaces_loaded: true });
                                db.collection("WorkSpace").where("workspace_owner", "==", current_user_id)
                                    .get().then((snapshot) => {
                                        snapshot.forEach(workspaceDoc => {
                                            workspace_data[workspaceDoc.data().floor_id] = workspaceDoc.data();
                                        });
                                    }).then(() => {
                                        console.log("loaded sucessfully")
                                        this.setState({
                                            workspaces_loaded: true,
                                            all_loaded: true
                                        })
                                    })
                            })
                    })
            });




    }

    drawAllFloors = () => {
        return (
            <Grid>

                <GeoJSONLayer
                    data={workspace_stairlines}
                    lineLayout={ReactMapboxGl.lineLayout}
                    linePaint={{
                        //    "fill-extrusion-height":3.7,
                        //    'fill-extrusion-base': 3.0,
                        "line-color": "#486a7b ",
                    }} />

                <GeoJSONLayer
                    data={workspace_walls}
                    fillExtrusionLayout={ReactMapboxGl.fillExtrusionLayout}
                    fillExtrusionPaint={{
                        "fill-extrusion-height": 1.5,
                        //    'fill-extrusion-base': 3.0,
                        "fill-extrusion-color": "#fff"
                    }} />

                <GeoJSONLayer
                    data={workspace_walls}
                    fillExtrusionLayout={ReactMapboxGl.fillExtrusionLayout}
                    fillExtrusionPaint={{
                        "fill-extrusion-height": 1.5,
                        'fill-extrusion-base': 1.49,
                        "fill-extrusion-color": "#486a7b"
                    }} />


                <GeoJSONLayer
                    data={workspace_hiddenlines}
                    lineLayout={ReactMapboxGl.lineLayout}
                    linePaint={{
                        //    "fill-extrusion-height":3.7,
                        //    'fill-extrusion-base': 3.0,
                        "line-dasharray": [2, 2],
                        "line-color": "#486a7b ",
                    }} />

                <GeoJSONLayer
                    data={workspace_hiddenlines2}
                    lineLayout={ReactMapboxGl.lineLayout}
                    linePaint={{
                        //    "fill-extrusion-height":3.7,
                        //    'fill-extrusion-base': 3.0,
                        "line-dasharray": [2, 2],
                        "line-color": "#486a7b ",
                    }} />



            </Grid>
        )

    }

    drawAllLines = () => {

        return (
            <div>
                <GeoJSONLayer
                    data={workspace_stairlines}
                    lineLayout={ReactMapboxGl.lineLayout}
                    linePaint={{
                        //    "fill-extrusion-height":3.7,
                        //    'fill-extrusion-base': 3.0,
                        "line-color": "#486a7b ",
                    }} />

                <GeoJSONLayer
                    data={workspace_hiddenlines}
                    lineLayout={ReactMapboxGl.lineLayout}
                    linePaint={{
                        //    "fill-extrusion-height":3.7,
                        //    'fill-extrusion-base': 3.0,
                        "line-dasharray": [2, 2],
                        "line-color": "#486a7b ",
                    }} />

                <GeoJSONLayer
                    data={workspace_hiddenlines2}
                    lineLayout={ReactMapboxGl.lineLayout}
                    linePaint={{
                        //    "fill-extrusion-height":3.7,
                        //    'fill-extrusion-base': 3.0,
                        "line-dasharray": [2, 2],
                        "line-color": "#486a7b ",
                    }} />

            </div>
        )

    }


    drawAllSpaces = (allspaceData) => {
        let all_spaces = [];

        allspaceData.forEach(element => {
            all_spaces.push(
                <GeoJSONLayer
                    id={element.space_id}
                    data={JSON.parse(element.space_geoJson)}
                    fillLayout={ReactMapboxGl.fillLayout}
                    fillPaint={{
                        "fill-color": "#cbd2d3",
                        "fill-outline-color": "#000000"
                    }}
                    fillOnClick={this.spaceClick}
                    fillOnMouseLeave={this.spaceMouseLeave}
                    fillOnMouseEnter={this.spaceMouseEnter}
                />
            );



        })

        return all_spaces;
    }

    drawAllWorksapces = (allWorkspaceData) => {
        // workspace_workspaces
        let allWorkspaces = [];

        allWorkspaceData.forEach(element => {
            console.log(element)
            allWorkspaces.push(
                <GeoJSONLayer
                    id={element.workspace_id}
                    data={JSON.parse(element.workspace_geoJson)}
                    fillLayout={ReactMapboxGl.fillLayout}
                    fillPaint={{
                        "fill-color": "#ffebb1",
                        "fill-outline-color": "#000000"
                    }}
                // fillOnClick={this.workspaceClick}
                // fillOnMouseLeave={this.workspaceMouseLeave}
                // fillOnMouseEnter={this.workspaceMouseEnter}
                />
            );



        })

        return allWorkspaces;
    }

    spaceClick = (e) => {
        this.setState({
            sensor_data_loading:true
        })
        const thisSpaceid = e.features[0].source;
        console.log(thisSpaceid);
        const occupancy = Math.random() * 150;
        e.target.setPaintProperty(previous_selectedSapce, 'fill-color', '#cbd2d3')
        e.target.setPaintProperty(e.features[0].layer.id, 'fill-color', '#fdfdfd')
        db.collection("Space").doc(thisSpaceid).get().then((doc) => {
            axios.get(`https://asia-southeast2-spacematch-de438.cloudfunctions.net/getSensorData?message=nothing&space_id=${available_sensors[Math.round(Math.random()*(available_sensors.length-1))]}&time=168h`).then(data=>{
                this.setState({
                    sensor_data_values:data.data,
                    sensor_data_loading:false
                })
            });
            this.setState({
                info_1: `Space name : ${doc.data().space_name}`,
                info_1_loaded: true,
                info_2: `Space area : ${Math.round(area(JSON.parse(doc.data().space_geoJson)),2)} m2`,
                info_2_loaded: true,
                show_info: true,
                current_selection: SPACE,
                topLeft_space_vacancy: 150 - occupancy,
                topLeft_space_occupancy: occupancy,
                topLeft_title: "Space occupancy"
            })
        })
        previous_selectedSapce = e.features[0].layer.id;
    }

    spaceMouseEnter = (e) => {
        e.target.getCanvas().style.cursor = 'pointer';
    }

    spaceMouseLeave = (e) => {
        e.target.getCanvas().style.cursor = 'default';

    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    handleBuildingClick = (buildingId) => {
        const selectedId = buildingId;
        console.log("gella", buildingId, "wgelleteen")
        this.setState({
            selectedListing: building_data[selectedId],
            center_loc: building_data[selectedId].building_location,
            zoom: 18.9
        })
    }


    handleFloorClick = (floorId) => {
        const currentFloor = floor_data[floorId]
        console.log(space_data)
        console.log(currentFloor);
        workspace_spaces = currentFloor.floor_spaces_geoJson.map(x => x)
        workspace_walls = JSON.parse(currentFloor.floor_walls_geoJson);
        workspace_stairlines = JSON.parse(currentFloor.floor_stairlines_geoJson);
        workspace_workspaces = currentFloor.floor_workSpaces_geoJson.map(x => x)
        workspace_hiddenlines = JSON.parse(currentFloor.floor_hiddenlines_geoJson)
        // console.log(currentFloor.floor_spaces_geoJson[0].space_geoJson)
        // workspace_walls = JSON.parse(currentFloor.floor_walls_geoJson);
        this.setState({
            floor_is_selected: true
        })
    }

    loadFloors = (b_element) => {
        const toreturn = [];
        b_element.building_floors.forEach(fl => {
            toreturn.push(
                <TreeItem nodeId={floor_data[fl].id}
                    label={`Level ${floor_data[fl].floor_number}`}
                    onClick={() => this.handleFloorClick(floor_data[fl].id)} />
            )
        })
        return toreturn;
    }

    loadTree = (ff) => {
        const { classes } = this.props;
        const items = []
        console.log(floor_data)
        for (const k in building_data) {
            if (Object.hasOwnProperty.call(building_data, k)) {
                const b_element = building_data[k];
                items.push(<TreeItem key={b_element.id}
                    nodeId={b_element.id}
                    className={classes.treeItemMaintitle}
                    label={b_element.building_name}
                    onClick={() => this.handleBuildingClick(b_element.id)}>

                    {this.loadFloors(b_element)}
                </TreeItem>)
            }
        }
        return items
    }
    render() {

        const { classes } = this.props;
        const {
            center_loc,
            space_loaded,
            n_floors,
            desk_selected,
            zoom,
            dashboardLeftPanelWidth,
            temp_desks,
            all_loaded,
            floors_loaded, floor_is_selected,
            info_1, info_2, info_1_loaded, info_2_loaded, show_info,
            current_selection,
            topLeft_space_occupancy,
            topLeft_space_vacancy,
            topLeft_title,
            sensor_data_values,
            sensor_data_loading
        } = this.state;


        // let header_link_text;
        let space_main_title;
        let space_performance;
        let space_occupancy;
        let SpaceBrain_rating;
        let space_subtitle;
        let selected_dataText;
        let floor_items;
        let space_N_floors = <Typography > --- </Typography>;

        

        // Create a JSON object to store the chart configurations
        const chartConfigs = {
            type: "doughnut2d", // The chart type
            width: "90%", // Width of the chart
            height: "30%", // Height of the chart
            dataFormat: "json", // Data type
            dataSource: {
                "chart": {
                    animation: 1,
                    animationDuration: 1,
                    "defaultCenterLabel": `${Math.round(topLeft_space_occupancy / (topLeft_space_occupancy + topLeft_space_vacancy) * 100, 2)} %`,
                    "centerLabel": "$label: $value",
                    centerLabelColor: "#486a7b",
                    centerLabelFontSize: 40,
                    centerLabelBold: true,
                    "decimals": "0",
                    "theme": "fusion",
                    // "bgColor": "#486a7b",
                    "bgColor": "#f6f6f4",
                    "doughnutRadius": "90%",
                    "pieRadius": "70%",
                    "slicingDistance": "10",
                    showLegend: false,
                },
                "data": [
                    {
                        "label": "Used",
                        "value": topLeft_space_occupancy.toString(),
                        "color": "#ffc107",
                        labelFontColor: "#486a7b"
                    },
                    {
                        "label": "Vacant",
                        "value": topLeft_space_vacancy.toString(),
                        "color": "#486a7b",
                        labelFontColor: "#486a7b"
                    }
                ]

            }
        };

        //Tempdata
        const Tempdata = {
            type: "line", // The chart type
            width: "49%", // Width of the chart
            height: "34%", // Height of the chart
            dataFormat: "json", // Data type
            dataSource: {
            "chart": {
                "caption": "Workspace temperature",
                "subCaption": "Last week",
                "xAxisName": "Day",
                "yAxisName": "Degrees",
                "lineThickness": "3",
                "theme": "fusion",
                "setAdaptiveYMin": true,
                "drawAnchors":false,
                "lineColor":"#486a7b"
            },
            "data": sensor_data_values
        }
    }

        // space_attributes 
        const workspaceSatisfaction = {
            type: 'bar2d',
            renderAt: 'chart-container',
            width: '22%',
            height: '40%',
            dataFormat: 'json',
            dataSource: 
                {
                    "chart": {
                        "caption": "WorkSpace staisfaction",
                        "yAxisName": "Average staisfaction",
                        "theme": "fusion",
                        showValues: true,
                        color: "#476a7b",
                        valueFontColor: "#476a7b",
                        paletteColors: "#476a7b",
                        showPrintMenuItem: true,
                        captionAlignment: "left",
                        captionFontSize: "15px",
                        captionFontColor : "#476a7b",
                        bgColor:"#f6f6f4",
                        alignCaptionWithCanvas: true,
                    },
                    "data": [
                        {
                            "label": "Ease of interaction",
                            "value": Math.round(Math.random()*100,2)
                        },
                        {
                            "label": "Amount of light",
                            "value": Math.round(Math.random()*100,2)
                        },
                        {
                            "label": "Comfort of furnishing",
                            "value": Math.round(Math.random()*100,2)
                        },
                        {
                            "label": "Building cleaness",
                            "value": Math.round(Math.random()*100,2)
                        },
                        {
                            "label": "Buildng maintenance",
                            "value": Math.round(Math.random()*100,2)
                        },
                        {
                            "label": "Amount of space",
                            "value": Math.round(Math.random()*100,2)
                        },
                        {
                            "label": "Visual comfort",
                            "value": Math.round(Math.random()*100,2)
                        },
                        {
                            "label": "Workspace cleaness",
                            "value": Math.round(Math.random()*100,2)
                        },
                        {
                            "label": "Colors and textures",
                            "value": Math.round(Math.random()*100,2)
                        },
                        {
                            "label": "Furniture adjustability",
                            "value": Math.round(Math.random()*100,2)
                        },
                        {
                            "label": "Visual privacy",
                            "value": Math.round(Math.random()*100,2)
                        }
                    ]
                }
            }
        
    

        if (all_loaded) {
            // header_link_text = <Typography> <a href='/space'> All spaces </a>  &gt; {all_data.space_name} </Typography>;
            space_main_title = <div><Typography>Building Name</Typography><Typography variant='h4'>NUS-SDE4</Typography></div>
            space_performance = <div><Typography>Space performance</Typography><Typography variant='h4'>{`${50+Math.round(Math.random() * 50, 1)} %`}</Typography></div>
            space_occupancy = <div><Typography>Space occupancy</Typography><Typography variant='h4'>{`${30+Math.round(Math.random() * 70, 1)} %`}</Typography></div>
            SpaceBrain_rating = <div><Typography>Total revenue</Typography><Typography color="#000000" variant='h4'>{`S$ ${2000+ Math.round(Math.random() * 3000)}`}</Typography></div>

            space_subtitle = <Typography variant='body2'>Workspace utilization and managment dashboard</Typography>
            space_N_floors = <Typography >{n_floors}</Typography>
        } else {
            // header_link_text = <Skeleton />
            // space_main_title = <Typography variant='h4'><Skeleton /></Typography>
            space_subtitle = <Typography variant='body1'><Skeleton /></Typography>
            // space_N_floors = <Typography variant='body1'><Skeleton /></Typography>
        }

        if (all_loaded) {
            floor_items = this.loadTree(floor_data);
        } else {
            floor_items = <TreeItem nodeId="loadingxxx" label="loading ..." />
        }



        if (desk_selected) {
            selected_dataText = (
                <Typography>data</Typography>
            )
        }
        else {

            selected_dataText = (
                <Grid container>
                    <Grid item sm={10} xs={10}>
                        <Box>
                            data insights to be added here
                </Box>
                    </Grid>
                    <Grid item sm={2} xs={2} align='right' >
                        <Box borderLeft={1} m={1} >
                            <Box><IconButton variant='contained' color='primary'> <Save /></IconButton></Box>
                            <Box><IconButton variant='contained' color='primary'> <Publish /></IconButton></Box>
                            <Box><IconButton variant='contained' color='primary'> <Visibility /></IconButton></Box>
                        </Box>
                    </Grid>
                </Grid>
            )
        }
        if (floors_loaded) {
            console.log("building data", building_data)
            console.log("floor data", floor_data[Object.keys(building_data)[0]])
            console.log("floor data keys", Object.keys(floor_data))
        }
        return (
            <Grid container>
                <Grid item sm={3}>
                    <Box
                        id="dashBoardLeftPanel"
                        m={0.5} height="40vh" boxShadow={1} bgcolor='#f6f6f4'
                        // display="flex"
                        alignItems="center"
                        textAlign='center'
                        justifyContent="center"
                    >
                        <Box p={1}
                            bgcolor="#889da8"
                            color="white"
                            display="flex"
                        >
                            <Typography variant="h6">
                                {topLeft_title}
                            </Typography>
                        </Box>
                        {all_loaded && (
                            <Box m={2}>
                                <ReactFC {...chartConfigs} />
                            </Box>
                        )}

                    </Box>
                </Grid>

                <Grid item sm={6} xs={12}>
                    <Box p={0.5} m={0.5} boxShadow={1} height="40vh">
                        {!all_loaded &&
                            <Skeleton variant="rect" width='100%' height='100%' />
                        }
                        {all_loaded && (
                            <Map
                                style="mapbox://styles/mapbox/light-v10"
                                center={center_loc}
                                zoom={[zoom]}
                                // pitch={[30]}
                                containerStyle={{
                                    position: 'relative',
                                    height: '100%',
                                    minHeight: '100%',
                                    width: '100%',
                                }}
                                onZoomEnd={(e) => { console.log(e.style) }}
                                animationOptions={{ duration: 2000 }}

                            >
                                {floor_is_selected && (
                                    this.drawAllSpaces(workspace_spaces)
                                )}

                                {floor_is_selected && (
                                    this.drawAllFloors()
                                )}

                                {floor_is_selected && (
                                    this.drawAllWorksapces(workspace_workspaces)
                                )}

                                {/*                             
  
this.drawAllFloors()
{this.drawAllLines()}
                                {this.drawAlldesks()} */}


                            </Map>
                        )}

                    </Box>
                </Grid>

                <Grid item sm={3} xs={12}>
                    <Grid item id="innerGrid" sm={12}>
                        <Box
                            m={0.5}
                            p={0}
                            borderRadius={0}
                            boxShadow={1}
                            justifyContent='left'
                            height="40vh"
                            bgcolor="#f6f6f4"
                            overflow="auto"

                        >
                            <Box>
                                <Grid item sm={12}>
                                    <Box p={1}
                                        bgcolor="primary.main"
                                        color="white"
                                        display="flex"
                                    >
                                        <Box flexGrow={1}>
                                            <Typography variant="h6">
                                                Your listings
                                        </Typography>
                                        </Box>
                                        <Box>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                size="small"
                                                className={classes.addListingButton}
                                                startIcon={<Add />}
                                                disableElevation
                                                component={Link} to="/listspace"

                                            >
                                                Add listing
                                        </Button>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item sm={12}>
                                    <Box p={1}>

                                        <TreeView
                                            aria-label="file system navigator"
                                            defaultCollapseIcon={<ExpandMoreIcon />}
                                            defaultExpandIcon={<ChevronRightIcon />}>

                                            <TreeItem nodeId="1" label="All listings">
                                                {floor_items}



                                            </TreeItem>
                                            <Divider />
                                            {/* <TreeItem nodeId="2" label="2. Floors" disabled>

                                            </TreeItem>
                                            <Divider/>
                                            <TreeItem nodeId="3" label="3. Spaces">

                                            </TreeItem>
                                            <Divider/>
                                            <TreeItem nodeId="4" label="4. Workspaces">

                                            </TreeItem> */}
                                            <Divider />

                                        </TreeView>
                                    </Box>
                                </Grid>
                            </Box>

                        </Box>
                    </Grid>
                </Grid>

                <Grid item sm={12}>
                    <Box display="flex"
                        p={0.5}
                        m={0.5}
                        boxShadow={1}
                        height='3vh'
                        bgcolor="#476A7B"
                        color="white"
                        alignItems="center"
                        // textAlign='center'
                        justifyContent="left"
                        pl={1}>
                        <Typography className={classes.tinyInfo}>
                            {show_info && (
                                `${info_1_loaded ? info_1 : "loading ... "} - ${info_2_loaded ? info_2 : "loading ..."}`
                            )}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item sm={3} >
                    <Box
                        display="flex"
                        p={0.5}
                        m={0.5}
                        boxShadow={1}
                        height='10vh'
                        bgcolor="#476A7B"
                        color="white"
                        alignItems="center"
                        // textAlign='center'
                        justifyContent="left"
                        pl={4}
                    >
                        {/* {header_link_text} */}
                        {space_main_title}
                        {/* {space_subtitle} */}
                    </Box>
                </Grid>
                <Grid item sm={3}>
                    <Box display="flex"
                        p={0.5}
                        m={0.5}
                        boxShadow={1}
                        height='10vh'
                        bgcolor="#889EA8"
                        alignItems="center"
                        justifyContent="left"
                        pl={4}>
                        <Grid sm={12}>
                            {space_performance}
                        </Grid>


                    </Box>
                </Grid>
                <Grid item sm={3}>
                    <Box display="flex"
                        p={0.5}
                        m={0.5}
                        boxShadow={1}
                        height='10vh'
                        bgcolor="#C5CED2"
                        alignItems="center"
                        justifyContent="left"
                        pl={4}>
                        <Grid sm={12}>
                            {space_occupancy}
                        </Grid>
                    </Box>
                </Grid>
                <Grid item sm={3} >
                    <Box  display="flex"
                        p={0.5}
                        m={0.5}
                        boxShadow={1}
                        height='10vh'
                        bgcolor="#f6f6f4"
                        alignItems="center"
                        justifyContent="left"
                        pl={4} >
                            {SpaceBrain_rating}
                        {/* <Paper> */}
                        <div id='qrcode'>&nbsp;</div>
                        {/* </Paper> */}
                    </Box>
                </Grid>

                <Grid item sm={3} >
                    <Box display="flex"
                        p={0.5}
                        m={0.5}
                        boxShadow={1}
                        height='35vh'
                        bgcolor="#f6f6f4"
                        alignItems="left"
                        textAlign='left'
                        justifyContent="left" 
                        overflow="auto">
                        {all_loaded && (
                            <Box>
                            <Grid item sm={12}><ReactFC {...workspaceSatisfaction} /></Grid>
                            </Box>
                        )}
                    </Box>
                </Grid>
                <Grid item sm={6} >
                    <Box display="flex"
                        p={0.5}
                        m={0.5}
                        boxShadow={1}
                        height='35vh'
                        bgcolor="#f6f6f4"
                        alignItems="left"
                        textAlign='left'
                        justifyContent="left" 
                        overflow="auto" >
                            {sensor_data_loading && (
                            <Box>
                            <Grid item sm={12}>Loading data ... </Grid>
                            </Box>
                        )}
{!sensor_data_loading && (
                            <Box>
                            <Grid item sm={12}><ReactFC {...Tempdata} /></Grid>
                            </Box>
                        )}
                    </Box>
                </Grid>

                <Grid item sm={3} >
                    
                    <Box display="flex"
                        p={0.5}
                        m={0.5}
                        boxShadow={1}
                        height='35vh'
                        bgcolor="white"
                        alignItems="center"
                        textAlign='center'
                        justifyContent="center" >
                            <Pannellum
                        width="100%"
                        height='34vh'
                        image={myImage}
                        pitch={10}
                        yaw={Math.random()*180}
                        hfov={110}
                        autoLoad
                        showZoomCtrl={false}
                        onLoad={() => {
                        console.log("panorama loaded");
                        }}
                    />
                    </Box>
                </Grid>
                
            </Grid>

        );
    }
}

space_dashboard.propTypes = {

};

export default withStyles(styles)(space_dashboard);