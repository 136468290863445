import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const config = {
    apiKey: "AIzaSyAhCSKtehUAIwzltd5jj4RfZhwE6vzmTuk",
    authDomain: "spacebrain-775b9.firebaseapp.com",
    databaseURL: "https://spacebrain-775b9-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "spacebrain-775b9",
    storageBucket: "spacebrain-775b9.appspot.com",
    messagingSenderId: "76182843320",
    appId: "1:76182843320:web:27bddc1c45fa2632a0a94d",
    measurementId: "G-CV8Y7Z6Y4R"
};

firebase.initializeApp(config);

export const auth = firebase.auth();

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({
    prompt: 'select_account'
});
export const signInWithGoogle = () => auth.signInWithPopup(provider);

export default firebase;