import React, { Component } from "react";
import axios from "axios";
import $ from "jquery";


class invitations extends Component {

    componentDidMount(){
        const inv_id_req = this.props.match.params.id;
        let stateData = null;
        const that = this;

        axios.post(`https://asia-southeast2-spacebrain-775b9.cloudfunctions.net/invite/get`, {inv_id:inv_id_req })
        .then(res =>
            {
              this.setState({ invitation_params : res.data })
            })
            
    }

    render() {
        console.log("ddddddd", this.state)
        var qrdata; 
        if (this.state)
        {
          console.log(this.state)
            qrdata = this.state.invitation_params.invitation_doc.inv_qrCodeUrl
            $("div#qrcode_location").html(()=>{
              return qrdata.replace("<svg", "<svg fill=\"#78909c\" id=\"qrCodeSVG\"")
            })
            // document.getElementById("qrcodexxxx").innerHTML()

        } else {
            qrdata = "loading ..."
        }
      return (
        <div>
            <h1> &nbsp;</h1>{" "}
          <div id="qrcode_location" > {qrdata}</div>{" "}
        </div>
      );
    }
  }


  
  
  export default invitations;