// //In your react App.js or yourComponent.js file add these lines to import
import React, { Component } from "react";
import * as Survey from "survey-knockout";
import * as SurveyCreator from "survey-creator";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
// // import * as Survey from "survey-react";
import "survey-knockout/survey.css";
import "survey-creator/survey-creator.css";
import axios from "axios";

function updateSurvey(id, json_body){
  axios.post(`https://asia-southeast2-spacebrain-775b9.cloudfunctions.net/invite/updateSurvey`, {survey_id:id , 
survey_body : json_body})
  .then(res =>
      {
        this.setState({
            invitation_params : res.data.res_survey.survey_body
          })

          console.log("updated successfully");
      }).catch(error=>{
        console.error(error);

      })

  
}


//https://asia-southeast2-spacebrain-775b9.cloudfunctions.net/invite/updateSurvey
class SurveyComponent extends Component {
  constructor(props) {
    super(props);
  }
  
  componentDidMount() {
    const survey_id_req = this.props.match.params.id;
    


    var mainColor = "#476a7b";
    var mainHoverColor = "#476a7b";
    var textColor = "#476a7b";
    var headerColor = "#476a7b";
    var headerBackgroundColor = "#ffc107";
    var bodyContainerBackgroundColor = "#f8f8f8";
    var defaultThemeColorsSurvey = Survey.StylesManager.ThemeColors["default"];
    defaultThemeColorsSurvey["$main-color"] = mainColor;
    defaultThemeColorsSurvey["$main-hover-color"] = mainHoverColor;
    defaultThemeColorsSurvey["$text-color"] = textColor;
    defaultThemeColorsSurvey["$header-color"] = headerColor;
    defaultThemeColorsSurvey[
      "$header-background-color"
    ] = headerBackgroundColor;
    defaultThemeColorsSurvey[
      "$body-container-background-color"
    ] = bodyContainerBackgroundColor;
    var defaultThemeColorsEditor =
      SurveyCreator.StylesManager.ThemeColors["default"];
    defaultThemeColorsEditor["$primary-color"] = mainColor;
    defaultThemeColorsEditor["$secondary-color"] = mainColor;
    defaultThemeColorsEditor["$primary-hover-color"] = mainHoverColor;
    defaultThemeColorsEditor["$primary-text-color"] = textColor;
    defaultThemeColorsEditor["$selection-border-color"] = mainColor;
    Survey.StylesManager.applyTheme();
    SurveyCreator.StylesManager.applyTheme();

    // Show Designer, Test Survey, JSON Editor and additionally Logic tabs
    var options = {
      showLogicTab: false,
      showJSONEditorTab: true,
      showTestSurveyTab: false,
      showEmbededSurveyTab: false,
      designerHeight: "1200px",
    };
    //create the SurveyJS Creator and render it in div with id equals to "creatorElement"
    
    axios.post(`https://asia-southeast2-spacebrain-775b9.cloudfunctions.net/invite/getSurvey`, {survey_id:survey_id_req })
    .then(res =>
        {
          return res.data.res_survey.survey_body;
        })
        .then(survey_body =>{
          var creator = new SurveyCreator.SurveyCreator("creatorElement", options);
          creator.text = JSON.stringify(survey_body) || JSON.stringify({title :"Loading", pages:[{name: "Loading ...", elements:[]}]})
          creator.showToolbox = "right";
          //Show property grid in the right container, combined with toolbox
          creator.showPropertyGrid = "right";
          //Make toolbox active by default
          creator.rightContainerActiveItem("toolbox");

          creator.isAutoSave = true;

          //Show state button here
          creator.showState = true;
          var localStorageName = "SaveLoadSurveyCreatorExample";
          console.log(creator.text);
          

          // Setting this callback will make visible the "Save" button
          creator.saveSurveyFunc = function (saveNo, callback) {
            updateSurvey(survey_id_req, JSON.parse(creator.text))
            callback(saveNo, true);
          };
        })

     
  }

  render() {
    return (
      <Grid justify="center" sm={12} xs={12} spacing={2}>
        <Grid container justify="center" sm={12} xs={12}>
          <Box component="span" m={2}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                console.log(
                  window.localStorage.getItem("SaveLoadSurveyCreatorExample")
                );
              }}
              disableElevation
            >
              Save and Share Survey
            </Button>
          </Box>
        </Grid>
        <div id="creatorElement">Loading survey ....</div>
      </Grid>
    );
  }
}

export default SurveyComponent;
