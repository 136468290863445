import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import "./App.css";

import { MuiThemeProvider } from '@material-ui/core'
import createMuiTheme from '@material-ui/core/styles/createMuiTheme'

//components
import Navbar from './components/navbar'
// pages
import home from './pages/home'
import list from './pages/list'
import find from './pages/findspace'
import invitations from './pages/invitations'
import SurveyComponent from './pages/survey_creator'
import GetSurvey from './pages/getSurvey'
import SpaceDashboard from './pages/spacev3'
import yourSpaces from './pages/yourSpaces'
// import GooglAuth from './pages/GoogleAuth'
import login from './components/login'
import signup from './components/signup'
import jwtDecode from 'jwt-decode'


const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#4f5b62',
      main: '#486a7b',
      dark: '#000a12',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff5551',
      main: '#ffc107',
      dark: '#C6A14E',
      contrastText: '#000',
    },
  }
})

const token = localStorage.FBIdToken;
// let authenticated;

if (token) {
  const decodedToken = jwtDecode(token);
  console.log(decodedToken);
  if (decodedToken.exp * 1000 < Date.now()) {
    // authenticated = false;
  } else {
    // authenticated = true;
  }
}

function App() {
  return <div className="App">
    <MuiThemeProvider theme={theme}>
      <Router>
        <Navbar />
        <Switch>
          <Route exact path="/" component={home}></Route>
          <Route path="/listspace" component={list}></Route>
          <Route path="/findspace" component={find}></Route>
          <Route path="/invitations/:id" component={invitations}></Route>
          <Route path="/createSurvey/:id" component={SurveyComponent}></Route>
          <Route path="/getSurvey/:id/:invitedBy?" component={GetSurvey}></Route>
          <Route path="/login" component={login}></Route>
          <Route path="/signup" component={signup}></Route>
          {/* <Route path="/space/:id" component={SpaceDashboard}></Route> */}
          <Route path="/space" component={SpaceDashboard}></Route>


        </Switch>
      </Router>
    </MuiThemeProvider>
  </div>;
}

export default App;
