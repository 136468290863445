import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import IconButton from "@material-ui/core/IconButton";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import { Container } from "@material-ui/core";
import Card from "@material-ui/core/Card";

import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

const tileData = [
    {
        title: "Comfy workspace",
        img:
            "https://images.unsplash.com/photo-1527192491265-7e15c55b1ed2?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1650&q=80",
    },
    {
        title: "Find the story behind this workplce",
        img:
            "https://images.unsplash.com/photo-1600508774634-4e11d34730e2?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80",
    },
    {
        title: "John earns 100k monthly from his workspace",
        img:
            "https://images.unsplash.com/photo-1604328727766-a151d1045ab4?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80",
    },
    {
        title: "HDB flats now can be rent for productivity",
        img:
            "https://images.unsplash.com/photo-1523908511403-7fc7b25592f4?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80",
    },
    {
        title: "Get your space ready in 2 weeks, figure out how?",
        img:
            "https://images.unsplash.com/photo-1556761175-5973dc0f32e7?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2090&q=80",
    },
];

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        marginTop: 10,
    },
    paper: {
        height: 140,
        width: "20vw",
    },
    control: {
        padding: theme.spacing(2),
    },
    gridList: {
        flexWrap: "nowrap",
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: "translateZ(0)",
    },
    title: {
        color: "white",
        backgroundColor: "rgba(0,0,0,0.5)",
    },
    titleBar: {
        background:
            "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
    },
    gridListTile: {
        height: "500px"
    }
}));

var list_services = [
    {
        id: 1,
        title: "HDB flat",
        img:
            "https://i.pinimg.com/originals/5d/7c/eb/5d7cebe77655986e7869d85e417b634a.jpg",
        desc:
            "You have extra rooms at home and you would likt to rent them as flexible office spaces.",
    },
    {
        id: 2,
        title: "Large office space",
        img:
            "https://i.pinimg.com/originals/2f/92/a4/2f92a4696b59002e6302e4b3704a3d55.png",
        desc:
            "You have a large office space and would like to rent it as flexible office area for corborates",
    },
    {
        id: 3,
        title: "Coffe shop",
        img:
            "https://user-images.githubusercontent.com/6969514/110335481-dc864400-805e-11eb-88f5-a39be908a31b.jpg",
        desc:
            "You have a coffeshop and would like to rent some spaces as flexible working areas.",
    },
];

export default function ListSpace() {
    const [spacing, setSpacing] = React.useState(2);
    const classes = useStyles();

    // const handleChange = (event) => {
    //     setSpacing(Number(event.target.value));
    // };

    return (
        <Container>
            <Grid sm={12}>
                <Typography variant="h5" color="textSecondary" component="p">
                    What is your space type ?
          </Typography>
            </Grid>
            <Grid container className={classes.root} spacing={2}>
                <Grid item xs={12}>
                    <Grid container justify="center" spacing={spacing}>
                        {list_services.map((value) => (
                            <Grid key={value["id"]} item sm={3}>
                                <Card>
                                    <CardActionArea>
                                        <CardMedia
                                            component="img"
                                            alt="Contemplative Reptile"
                                            height="140"
                                            image={value["img"]}
                                            title="Contemplative Reptile"
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="h2">
                                                {value["title"]}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                                component="p"
                                            >
                                                {value["desc"]}
                        -- Terms and conditions apply.
                      </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                    <CardActions>
                                        <Button size="small" color="primary">
                                            Share
                    </Button>
                                        <Button size="small" color="primary">
                                            Learn More
                    </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>

                {/* <div className={classes.root}> */}
                <Divider />
                <Grid sm={12}>
                    <Divider variant="middle" />
                    <Typography variant="h5" color="textSecondary" component="p">
                        Discover our successful stories :
          </Typography>
                    <Divider variant="middle" />
                </Grid>

                <GridList className={classes.gridList} cols={2.5} >
                    {tileData.map((tile) => (
                        <GridListTile key={tile.img} rows={1.5}>
                            <img src={tile.img} alt={tile.title} />
                            <GridListTileBar
                                title={tile.title}
                                classes={{
                                    root: classes.titleBar,
                                    title: classes.title,
                                }}
                                actionIcon={
                                    <IconButton aria-label={`star ${tile.title}`}>
                                        <StarBorderIcon className={classes.title} />
                                    </IconButton>
                                }
                            />
                        </GridListTile>
                    ))}
                </GridList>
            </Grid>
        </Container>
    );
}
