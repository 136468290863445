import { React, useState, useEffect } from "react";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Paper from "@material-ui/core/Paper";
import CardMedia from "@material-ui/core/CardMedia";
import { CardContent, Typography } from "@material-ui/core";
import {Link} from "react-router-dom";
import { createMuiTheme } from "@material-ui/core/styles";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import Button from "@material-ui/core/Button"
import Box from '@material-ui/core/Box'

import ReactMapGl, { Source, Layer } from "react-map-gl";
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;


var access_token = 
  "pk.eyJ1IjoibWFobW91ZG91ZjExMSIsImEiOiJjazcyb2xydWowNGo4M2t1dXhmMGN0M3dpIn0.sW9s_aQkI5eIRcN50sXk3w";

const theme = createMuiTheme();

theme.typography.h3 = {
  // marginTop:"20px",
  fontSize: "1.5rem",
  "@media (min-width:600px)": {
    fontSize: "1.0rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.0rem",
  },
};

export default function Home() {
  const [viewport, setViewport] = useState({
    latitude: 1.3589843200388694,
    longitude: 103.81830693530982,
    zoom: 10,
    width: "100%",
    height: "100vh",
    // mapStyle: "mapbox://styles/mahmoudouf111/ckkzdla3e1t3n17ocasxxev7u"
  });

  const [workSapceCounter, setWorkSpaceCounter] = useState(8000+Math.round(Math.random()*5000))

  const sde4_walls  = 'https://gist.githubusercontent.com/MahmoudAbdelRahman/e4256e21ee51d53d76ab8806592cc3de/raw/ce8f367842e16649971b4c2f6e984fbb482c4f54/sde4_level3_walls.json'
  const sde4 = {
    id: 'SDE4_walls',
    type: 'fill-extrusion',
    source: 'building-data',
    paint:{
      'fill-extrusion-color': '#fff',
 
// Get fill-extrusion-height from the source 'height' property.
'fill-extrusion-height': 1.2,
    }
  };

  let data_toshow = [
    {
      key: 0,
      title: "SpaceMatch me",
      url: "/imgs/find_workspace_style2.png",
      description: "",
      link: "/findspace",
      //"If you are looking for a workspace for you and/or your team, "+
      //"we utilize sophisticated state-of-the-art algorithms personalized to boost your productivity and satisfaction "
    },
    {
      key: 1,
      title: "Add your spaces",
      url: "/imgs/offer_workspace_style2.png",
      description:
        "",
      link: "/listspace",
    },
    {
      key: 3,
      title: "Dashboard",
      url: "/imgs/asset_management_dashboard.png",
      description:
        "",
        link: "/space",
    },
    {
      key: 4,
      title: "Workspace optimization",
      url: "/imgs/floor_plan_optimization.png",
      description:
        "",
    },
    {
      key: 5,
      title: "COVID-19 solutions",
      url: "/imgs/covid_response.png",
      description:
        "",
    },
    {
      key: 5,
      title: "Become a partner",
      url: "/imgs/partner.png",
      description:
        "",
    },

  ];

  useEffect(() => {
    let interval=null;
    interval = setInterval(()=>{
      setWorkSpaceCounter(workSapceCounter => workSapceCounter+1)
    }, 50 + Math.round(Math.random()*4500))
    return () => clearInterval(interval)
  }, [workSapceCounter])

  return (
    <Grid container justify="center">
      <Grid container justify="space-around" sm={8} xs={10} spacing={0}>
        <Card item elevation={0} square color="secondary">
        </Card>{" "}
      </Grid>{" "}
      <Grid container justify="space-around" sm={4} xs={0} spacing={0}>
      </Grid>{" "}
      <Grid container justify="space-around" sm={6} xs={10} spacing={0}>
        <Grid item justify="space-around" sm={12} xs={12} spacing={0}>
          <Box p={2}  m={1}>
            <Typography variant="h6">
            <b>SpaceMatch Xchange!</b>
            </Typography>
              <Typography variant="body" color="textSecondary">
                Share your workspace, and get access to <Typography display="inline" color="textPrimary"><b>{workSapceCounter}</b></Typography> workspaces on our system worldwide. 
              </Typography>
              <br/><br/>
              <Typography variant="h6">
              <b>SpaceMatch just share! </b>
            </Typography>
              <Typography variant="body" color="textSecondary">
                Share your workspace and earn money once it is used. 
              </Typography>
              <br/><br/>
              <Typography variant="h6">
              <b>It is all about workspace experience!</b> 
            </Typography>
              <Typography variant="body" color="textSecondary">
                Give your visitors a unique workspace experience and get high rating and better visibility. 
              </Typography>
          </Box>{" "}
        </Grid>{" "}
        <Grid item sm={12}>
          <Box  p={2} m={2}>
            {/* <Typography variant="h5">
              <b>Quick links</b>
            </Typography> */}
          </Box>
          <Box p={2} m={2}>
          <Grid container sm={12} justify="space-around" alignContent="center">
            
        {data_toshow.map((value) => (
          <Grid item sm={4} xs={6}>
            <center>
              <Box component={Link} to={value["link"]}>
            <img src={process.env.PUBLIC_URL + value["url"]} width="100px"/>
              <Typography>{value["title"]}</Typography>
              <Box> <Typography variant="caption" fontSize="x-small" color="textSecondary"> {value["description"]} </Typography></Box>{" "}
              </Box>
              </center>
            </Grid>
        ))}
        
        </Grid>
        </Box>
      </Grid>
      <Grid item sm={12} >
      <Box  p={2} m={2} color="text.primary">
            <Typography variant="h5">
            <b>The time for flexi workspace is just now.</b>
            </Typography>

          </Box>
          <Box p={2} m={2} >
         
          <img src={process.env.PUBLIC_URL +"/imgs/philosophy.png"} width="100%"/>
          </Box>
      </Grid>

      <Grid item sm={12} >
      <Box  p={2} m={2} color="text.primary">
            <Typography variant="h5">
            <b>Our value</b>
            </Typography>
          </Box>
          <Box p={1} m={1} >
          <img src={process.env.PUBLIC_URL +"/imgs/value.png"} width="100%"/>
          </Box>
      </Grid>
      </Grid>
      
      <Grid container justify="space-around" sm={6} xs={12} spacing={1}>
        <Grid className="sidebarmap">
          <ReactMapGl
            {...viewport}
            mapboxApiAccessToken={access_token}
            //mapStyle= {'mapbox://styles/mapbox/streets-v11'} //{'mapbox://styles/mahmoudouf111/ckkzdla3e1t3n17ocasxxev7u'}
            onViewportChange={(viewport) => {
              setViewport(viewport);
            }}
          >
            <Source id="building-data" type="geojson" data={sde4_walls}></Source>
            <Layer  {...sde4}>
              </Layer>re
              </ReactMapGl>{" "}
        </Grid>{" "}
      </Grid>{" "}
    </Grid>
  );
}
