import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker'
import { Divider, Button } from '@material-ui/core';
import HomeWork from '@material-ui/icons/HomeWork'
import LocalCafe from '@material-ui/icons/LocalCafe'
import Card from '@material-ui/core/Card'
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { FixedSizeList } from 'react-window';







function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}





TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  gridcont: {
    display: "flex"
  },
  paper:{
    padding: theme.spacing(3, 2),
    // height: ,
    display: "flex",
    // width: "100%",
    flexDirection: "column",
    justifyContent: "center",
    height:"500px",
    backgroundColor:"#00000000"
  },
  cover:{
    width:"100vw",
    height: "100vh",
    position:"fixed",
    backgroundImage:"url(/imgs/bk_office_07.jpeg)",
    backgroundPosition:"center",
    backgroundSize:"cover",
    backgroundRepeat:"no-repeat",
    top:"0px",
    left:"0px",
    zIndex:-100,
    // opacity:0.5
  },
  coverOverlay: {
    width:"100vw",
    height: "100vh",
    position:"fixed",
    // backgroundImage:"url(/imgs/cover_overlay4.png)",
    backgroundPosition:"center",
    backgroundSize:"2px",
    top:"0px",
    left:"0px",
    zIndex:-100,
    // opacity:0.5
  },
  h1Text : {
    backgroundColor: "#486a7b44",
    padding: 15,
    borderRadius: 10,
    color: 'white',
  },
  paperCheckbox : {
    spacing: 1, 
    padding : 5,
  },
  listText :{
    lineHeight: '0px',
    margin:'0px',
    padding: '0px 5px',
    fontWeight:'bold',
    fontSize:"8px",
  
  },
  listItem:{
    lineHeight: 0,
    margin: "2px",
    padding: "0px",
    fontSize:"8px",
  }, 
  package_paper:{
    height: "220px"
  }
}));


const now = new Date();
const yesterdayBegin = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
const todayNoon = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 12);



function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}


const names = [
  'Home style',
  'Coffee shop style',
  'Coworking style',
  'Dedicated office',
];


export default function SimpleTabs() {

  

  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [dtrangevalue, dtrangeonChange] = React.useState([yesterdayBegin, todayNoon]);




  const handleChange = (event, newValue) => {
    setValue(newValue);
  };



  const listTexts =[
    "- Space utilization",
    "- Employees dashboard",
    "- Space management system",
    "- Employee dynamic allocation",
    "- S$ 100/employee/month"
  ]

  const listTexts2 =[
    "- Space utilization",
    "- Dynamic space allocaiton",
    "- Space management system",
    "- Floor plan integration *",
    "- Sensor deployment",
    "- S$ 110/employee/month"
  ]
  return (

    
    <Grid container className={classes.gridcont}>
     <Grid  container sm={7} xs={1}><Paper className={classes.paper} elevation={0} >
       <Typography 
      //  align="center" 
       className={classes.h1Text}
       variant="h2"
       >
           Finding a workspace has never been easier!
       </Typography>
       </Paper>
       </Grid> 
     <Grid container sm={4} xs={1}>
     <Paper className={classes.paper} elevation={0} >
    <div className={classes.root}>
      <AppBar position="static" color="default" elevation={0}>
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example"
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        >
          <Tab label="Individual workspace" {...a11yProps(0)} icon={<img src="/imgs/individual_workspace.png" width="60px" height="60px"/>}/>
          <Tab label="Group workspace" {...a11yProps(1)} icon={<img src="/imgs/groupworkspace.png" width="60px" height="60px" />}  />
          <Tab label="Interprise solutions" {...a11yProps(2)} icon={<img src="/imgs/enterprise.png" width="60px" height="60px" />}  />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
      <Grid 
      container 
      className={classes.root}
      justify="space-around"
      > 
      <Grid sm={12}>
        <Paper elevation={0} color="secondary" square={true} className={classes.paperCheckbox}>
          <Typography color="primary">Pick date and time </Typography>
          <Box >
      <DateTimeRangePicker
            onChange={dtrangeonChange}
            value={dtrangevalue}
          />
      </Box>
      </Paper>
      </Grid>
      </Grid>
      <Grid 
      container 
      className={classes.root}
      justify="space-around"
      > 
        <Grid sm={6}>
        <Divider />
        <Paper 
        elevation={0} 
        color="secondary" 
        square={true} 
        className={classes.paperCheckbox}>
          <Typography color="primary">Workspace style?</Typography>
          <Box >
          <div>
      {/* Default checked */}
      <div class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" id="defaultChecked2"/>
  <label class="custom-control-label" for="defaultChecked2">Home style </label>
      </div>
      <div class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" id="defaultChecked2"/>
        <label class="custom-control-label" for="defaultChecked2">Coffeshop style</label>
      </div>
      <div class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" id="defaultChecked2"/>
        <label class="custom-control-label" for="defaultChecked2">Coworking style</label>
      </div>
      <div class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" id="defaultChecked2"/>
        <label class="custom-control-label" for="defaultChecked2">Dedicated office</label>
      </div>
    </div>
          </Box>
        </Paper>
        </Grid>
        <Grid sm={6}>
        <Divider />
        <Paper 
        elevation={0} 
        color="secondary" 
        square={true} 
        className={classes.paperCheckbox}>
          <Typography color="primary">Workplace preferences: </Typography>
          <Box >
          <div>
      {/* Default checked */}
      <div class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" id="defaultChecked2"/>
        <label class="custom-control-label" for="defaultChecked2">Near home</label>
      </div>
      <div class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" id="defaultChecked2"/>
        <label class="custom-control-label" for="defaultChecked2">Best view</label>
      </div>
      <div class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" id="defaultChecked2"/>
        <label class="custom-control-label" for="defaultChecked2">Networking</label>
      </div>
      <div class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" id="defaultChecked2"/>
        <label class="custom-control-label" for="defaultChecked2">Less noise</label>
      </div>
      <div class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" id="defaultChecked2"/>
        <label class="custom-control-label" for="defaultChecked2">Amenities</label>
      </div>
    </div>
          </Box>
        </Paper>
        </Grid>
    
        <Grid sm={12}>
        <Divider />
        <Paper 
        elevation={0} 
        color="secondary" 
        square={true} 
        className={classes.paperCheckbox}>
          <Typography color="primary">Your address: </Typography>
        </Paper>
        </Grid>

      </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        Item Two
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Grid container
        justify="space-between"
        spacing={1}>

            <Grid sm={12}>
            <Box m={0.25}>
              <Typography
              variant='h6'
              color='primaryText'
              >Select a package:</Typography>
              </Box>
              </Grid>
          <Grid sm={6}>
            <Box m={0.25}>
            <Paper elevation={0}
            variant='outlined'
            className = {classes.package_paper}
            >
               <Typography 
               align='center'
               color='primary'> &lt; 500 users</Typography>
        {listTexts.map((item) => (
          <ListItem className={classes.listItem}  key={`item-${item}`}>
            <ListItemText className={classes.listText} primary={`${item}`} />
          </ListItem>
        ))}
        
          
               
              
            </Paper>
            </Box>
            
            <Box align='center' m={1}>
            <Button variant="contained" color="primary" size="large" >Select</Button>
            </Box>
          </Grid>
          <Grid sm={6}>
          <Box m={0.25}>
            <Paper elevation={0}
            variant='outlined'
            m={1}
            className = {classes.package_paper}>
              <Typography 
              align='center'
              color='primary'> &gt; 500 users</Typography>

{listTexts2.map((item) => (
          <ListItem className={classes.listItem}  key={`item-${item}`}>
            <ListItemText className={classes.listText} primary={`${item}`} />
          </ListItem>
        ))}
            
            </Paper>
            </Box>
            <Box align='center' m={1}>
            <Button variant="contained" color="primary" size="large" >Select</Button>
            </Box>
          </Grid>
        </Grid>
      </TabPanel>
    </div>
    </Paper>
    </Grid>
    <Grid  container sm={1} xs={1}>
    <div className={classes.cover}>
      &nbsp;
    </div>

    <div className={classes.coverOverlay}>
      &nbsp;
    </div>
    </Grid>
    </Grid>
  );
}
