
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import jwtDecode from 'jwt-decode'
import firebase from '../firebase/firebase.util';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {Link} from "react-router-dom";




class yourSpaces extends Component {
    constructor() {
        super();

        this.state = {
            user_id: null,
            owner_spaces: null,
            authenticated: false,
            loading: true,
            columns: [{ field: 'id', headerName: 'ID', width: 70 },
            { field: 'space_name', headerName: 'Name', width: 130 },
            { field: 'space_type', headerName: 'Type', width: 130 },
            { field: 'performance', headerName: 'Performance', width: 130 },
            { field: 'edit', headerName: 'Edit', width: 130 }],
            rows: [
                {
                    id: 0,
                    'space_name': 'loading',
                    'performance': '...',
                    'edit': '...'
                }
            ]
        }
    }

    componentDidMount() {

        const token = localStorage.FBIdToken;


        if (token) {
            console.log("HI I'm HERE FROM TOKEN  ")
            const decodedToken = jwtDecode(token);
            console.log(decodedToken);

            const db = firebase.firestore();
            db.collection('owner-spaces').doc(decodedToken.user_id).get()
                .then((doc) => {
                    if (doc.exists) {
                        console.log(doc.data().spaces);
                        let rowList = []
                        for (let i = 0; i < doc.data().spaces.length; i++) {
                            const element = doc.data().spaces[i];
                            rowList.push({
                                id: i + 1,
                                space_name: element.space_name,
                                space_type: 'full building',
                                performance: `${Math.random() * 100}%`,
                                edit: 'edit',
                                space_id: element.space_id
                            })
                        }

                        console.log(JSON.stringify(rowList));

                        this.setState({
                            authenticated: true,
                            user_id: decodedToken.user_id,
                            owner_spaces: doc.data(),
                            loading: false,
                            columns: [{ field: 'id', headerName: 'ID', width: 70 },
                            { field: 'space_name', headerName: 'Name', width: 130 },
                            { field: 'space_type', headerName: 'Type', width: 130 },
                            { field: 'performance', headerName: 'Performance', width: 130 },
                            { field: 'edit', headerName: 'Edit', width: 130 }],
                            rows: rowList
                        });
                    } else {
                        this.setState({
                            authenticated: true,
                            user_id: decodedToken.user_id,
                            owner_spaces: null,
                            loading: false,
                        });
                    }

                })

        }
    }

    render() {
        const { user_id, owner_spaces, loading, columns, rows } = this.state;
        return (
            <Grid container>
                <Grid item sm={1}></Grid>
                <Grid item sm={10} xs={12}>
                    <Box p={1}>
                        {
                            loading && (
                                <Typography variant='h3'>
                                    Loading ...
                                </Typography>
                            )
                        }
                        {
                            !loading && (
                                <Box>
                                    <Typography>
                                        Your Spaces :
                                </Typography>
                                <TableContainer component={Paper}>
      <Table  aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell align="right">Space Name</TableCell>
            <TableCell align="right">Type</TableCell>
            <TableCell align="right">Performance</TableCell>
            <TableCell align="right">edit</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                {row.id}
              </TableCell>
              <TableCell align="right" component={Link} to={`/space/${row.space_id}`}>{row.space_name}</TableCell>
              <TableCell align="right">{row.space_type}</TableCell>
              <TableCell align="right">{row.performance}</TableCell>
              <TableCell align="right">{row.edit}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
                                </Box>
                            )
                        }
                    </Box>
                </Grid>
                <Grid item sm={1}></Grid>
            </Grid>
        );
    }
}

yourSpaces.propTypes = {

};

export default yourSpaces;